<template>
  <div class="dbview">
      <div class="viewbox">
         <div class="viewcont">
           <dbkheader v-model="query" @search="dbkSearch"></dbkheader>
           <div class="width1200">
             <div class="viewtop">
               您搜索的关键词:<label>{{query}}</label>共返回<span>{{totalCount}}</span>条记录
             </div>
             <div class="viewresult">
               <ul>
                 <li v-for="(item,index) in dataList" :key="index">
                   <div class="info" >
                     <h3 @click="toInfoPage(item.id)" v-html="item.highlightName"></h3>
                     <p class="p1">{{item.enName}}  <label>出处：{{item.sourceFrom}}</label></p>
                     <p class="p2 ellipsis3" @click="toInfoPage(item.id)" v-html="item.abstractWord"></p>
                     <div class="hotLine">
                       热链接：
                       <a v-for="(link,i) in item.hotWords" :key="i" href="javascript:;" @click="toInfoPage(link)">{{link}}</a>
                     </div>
                   </div>
                   <img v-if="item.picSrc" :src="item.picSrc" align="right">
                 </li>
               </ul>
             </div>
             <transition leave-active-class="animated fadeOut">
               <div class="backTop wow fadeIn" v-show="btnFlag" @click="backTop"></div>
             </transition>
             <el-pagination
                 background
                 @size-change="handleSizeChange"
                 @current-change="handleCurrentChange"
                 :current-page="currentPage"
                 :page-sizes="[10, 20]"
                 :page-size="10"
                 layout="total, sizes, prev, pager, next, jumper"
                 :total="totalCount"
             >
             </el-pagination>
           </div>
         </div>
      </div>
    <dbkfooter></dbkfooter>
  </div>
</template>

<script>
import dbkheader from '@/components/dbkheader.vue';
import dbkfooter from '@/components/dbkfooter.vue';
import { WOW } from "wowjs";
import {dbkSearch} from '@/api/home'
export default {
  name: "dbkView",
  components:{
    dbkheader,
    dbkfooter
  },
  data(){
    return{
      currentPage: 1,
      btnFlag:false,
      scrollTop:0,
      query:'',
      pageNo:1,
      pageSize:10,
      totalCount:0,
      dataList:[],
    }
  },
  created () {
    if (this.$route.query.inputVal) {
      this.query=this.$route.query.inputVal;
      this.getResult();
    }
  },
  mounted() {
    window.addEventListener('scroll',this.scrollToTop);
    new WOW().init();
  },
  methods:{
    getResult(){
      dbkSearch(this.query,this.pageNo,this.pageSize).then((res)=>{
        this.totalCount=res.data.totalCount;
        this.pageNo=res.data.pageNo;
        this.dataList=res.data.page.map((item)=>{
          return {
            id:item.id,
            cnName:item.cnName,
            highlightName:item.highlightName,
            enName:item.enName,
            sourceFrom:item.sourceFrom,
            picSrc:item.picSrc,
            abstractWord:item.abstractWord,
            hotWords:item.hotWords.split(";").filter((s)=>{return s && s.trim()}).slice(0,5)
          }});
      })
    },
    toInfoPage(name){
      this.$router.push({
        path:'/dbkdetail',
        query:{name:name,query:this.query}
      })

    },
    /*分页*/
    handleSizeChange(val) {
      this.pageSize=val;
      this.getResult();
    },
    handleCurrentChange(val) {
      this.pageNo=val;
      this.getResult();
    },
    /*回到顶部*/
    backTop(){
       const that = this;
       let timer = setInterval(()=>{
         let ispeed = Math.floor(-that.scrollTop/5)
         document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
         if (that.scrollTop === 0){
           clearInterval(timer)
         }
       },16)
    },
    scrollToTop(){
      const that = this;
      let scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60){
        that.btnFlag = true
      }else {
        that.btnFlag = false
      }
    },
    dbkSearch(){
      this.$router.push({path:'dbkView',query:{inputVal:this.query}})
      this.getResult();
    }
  }
}
</script>

<style scoped>
.info{
  cursor: pointer;
}
a{text-decoration: none}
.dbview{
  background: #f5f6f5 !important;
  min-height: 100%;
}
.viewbox{
  min-height: 100vh;
  height: auto;
  margin: 0 auto -116px;
}
.viewcont{
  padding-bottom: 116px;
}
.width1200{
  width: 1200px;
  margin: auto;
  position: relative;
  padding-bottom: 70px;
}
.viewtop{
  width: 100%;
  height: 40px;
  border: 1px solid #E6E6E6;
  margin: 12px 0px 10px 0px;
  font-size: 14px;
  color: #4D4D4D;
  line-height: 40px;
  text-align: left;
  padding-left: 17px;
  box-sizing: border-box;
}
.viewtop label{
  font-size: 16px;
  color: #C01937;
  padding: 0px 11px 0px 15px;
}
.viewtop span{
  color: #C01937;
  padding: 0px 5px;
}
.viewresult{
  text-align: left;
}
.viewresult ul{
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.06);
}
.viewresult ul li{
  padding: 20px 10px 20px 18px;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
}
.viewresult h3{
  font-size: 22px;
  color: #1A1A1A;
  font-weight: 400;
  padding-bottom: 10px;
}
.viewresult h3 span{
  font-size: 16px;
  color: #4D4D4D;
}
.viewresult .p1{
  font-size: 12px;
  color: #333333;
  padding-bottom: 15px;
}
.viewresult .p1 span{
  color: #666666;
}
.viewresult .p1 label{
  color: #999999;
}
.viewresult .p2{
  color: #4d4d4d;
  font-size: 16px;
  line-height: 30px;
}
.viewresult .p2 .signblue{
  color: #2464A9;
}
.viewresult .p2 .signred{
  color: #C01937;
}
.viewresult img{
  margin-left: 18px;
  width: 192px;
  height: 192px;
  flex-shrink: 0;
}
.ellipsis3{
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
  overflow:hidden;
}
.hotLine{
  font-size: 12px;
  color: #999999;
  padding-top: 15px;
}
.hotLine a{
  font-size: 12px;
  color: #808080;
  padding: 4px 6px;
  background: #E6E6E6;
  margin-right: 10px;
  line-height: 12px;
}

.backTop{
  position: sticky;
  width: 48px;
  height: 46px;
  background-image: url("../../assets/images/top.png");
  background-size: 100% 100%;
  border-right: 3px;
  margin-left: 103%;
  bottom: 200px;
  cursor: pointer;
}
</style>
<style>
html, body, #app{
  height: 100%;
  background: #f5f6f5 !important;
}
.viewcont .el-pagination{
  padding: 50px 0px;
}
.viewcont .el-pager li{
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.viewcont .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  border: 1px solid #CCCCCC !important;
  background: linear-gradient(0deg, #F0F0F0 0%, #FFFFFF 100%) ;
}
.viewcont .el-pagination.is-background .el-pager li:not(.disabled):hover{
  background: #C01937 !important;
  color: #ffffff !important;
}
.viewcont .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.viewcont .el-pagination__editor.el-input .el-input__inner,.el-input--mini .el-input__inner,.el-pagination button, .el-pagination span:not([class*=suffix]){
  height: 36px;
  line-height: 36px;
}
.viewcont .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #C01937 !important;
}
</style>
